import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import { requestsApi, smsApi } from "../../../api/api";
import { AccountContext } from "../../../contexts/AccountContext";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import SmsIcon from "@mui/icons-material/Sms";

export const ActiveToolbar = ({ active, setActive, search, setSearch, setIsLoading, setHighlightActive }) => {
  const { user } = useContext(AccountContext);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (fields) => {
      setIsLoading(true);
      setSearch(fields.search);
      requestsApi
        .searchActiveByAts(user.atsIcaoLocationIndicator, fields.search)
        .then((res) => {
          setHighlightActive();
          setActive(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    },
  });

  const refresh = () => {
    setIsLoading(true);
    requestsApi
      .getActiveByAts(user.atsIcaoLocationIndicator)
      .then((res) => {
        setActive(res.data);
        setHighlightActive();
        setSearch("");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const cancelAll = () => {
    if (window.confirm(t("confirm_abortall"))) {
      setIsLoading(true);
      smsApi
        .active(user.atsIcaoLocationIndicator)
        .then((res) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form className="table-toolbar" onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Button sx={{ textTransform: "none" }} size="small" onClick={refresh} variant="outlined" startIcon={<RefreshIcon />}>
          {t("refresh")}
        </Button>
        <div style={{ marginLeft: 3, marginRight: 3 }}></div>
        <Button sx={{ textTransform: "none" }} color="error" size="small" onClick={cancelAll} variant="outlined" startIcon={<SmsIcon />}>
          {t("cancel_all_immediately")}
        </Button>
        {/* <Tooltip title={t("refresh")}>
          <IconButton size={window.innerWidth > 1000 ? "large" : "medium"} onClick={refresh} aria-label="refresh">
            <RefreshIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Tooltip> */}
        {/* <Tooltip title={t("cancel_all_immediately")}>
          <IconButton size={window.innerWidth > 1000 ? "large" : "medium"} onClick={cancelAll} aria-label="refresh">
            <CancelIcon fontSize="inherit" color="error" />
          </IconButton>
        </Tooltip> */}
        {search && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            <div style={{ marginLeft: 10 }}></div>
            {t("search_results")} "{search}"{" "}
            <IconButton
              size="small"
              aria-label="cancel"
              component="span"
              onClick={() => {
                requestsApi
                  .getActiveByAts(user.atsIcaoLocationIndicator)
                  .then((res) => {
                    setActive(res.data);
                    setSearch("");
                  })
                  .catch((err) => {});
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
        )}
      </div>
      <TextField
        sx={{ width: window.innerWidth > 450 ? "40%" : "100%", marginBottom: 1, marginTop: 1 }}
        variant="outlined"
        size="small"
        id="search"
        name="search"
        value={formik.values.search}
        onChange={formik.handleChange}
        placeholder={t("search")}
        InputProps={{
          type: "search",
          startAdornment: <SearchIcon fontSize="medium" />,
        }}
      />
    </form>
  );
};
