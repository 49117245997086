import axios from "axios";
import { convertToUnixTimeStamp, getLang, wktPolygonToCoordinateArray } from "../common/utils/functions";

export const accountApi = {
  login: async (user) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/login`, {
      email: user.email,
      password: user.password,
    });
  },
  register: async (user) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/register`, {
      email: user.email,
      displayName: user.name,
      phoneNumber: user.phoneNumber,
      password: user.password,
    });
  },
  verifyEmail: async (token, email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/verify-email?email=${email}&token=${token}`);
  },
  resendEmailConfirm: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/resend-email-confirm?email=${email}`);
  },
  // resetPassword: async (token, email, password) => {
  //   return await axios.post(`${lang}/account/reset-password?token=${token}&email=${email}&password=${password}`);
  // },
  resetPassword: async (token, email, password) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/reset-password`, {
      email: email,
      password: password,
      token: token,
    });
  },
  resetPasswordRequest: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/reset-password-confirmation-link?email=${email}`);
  },
  checkToken: async (token) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/check-token`);
  },
  // changePassword: async (email, currentPassword, newPassword) => {
  //   return await axios.post(`${lang}/account/change-password?email=${email}&currentPassword=${currentPassword}&newPassword=${newPassword}`, {
  //     email: email,
  //     currentPassword: currentPassword,
  //     newPassword: newPassword,
  //   });
  // },
  changePassword: async (email, currentPassword, newPassword) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-password`, {
      email: email,
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  },
  // changePhoneNumber: async (email, newPhoneNumber) => {
  //   return await axios.post(`${lang}/account/change-phone-number?email=${email}&newPhoneNumber=${newPhoneNumber}`);
  // },
  changePhoneNumber: async (email, newPhoneNumber) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-phone-number`, {
      email: email,
      phoneNumber: newPhoneNumber,
    });
  },
  changeEmailConfirmationLink: async (currentEmail, newEmail) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-email-confirmation-link?currentEmail=${currentEmail}&newEmail=${newEmail}`);
  },
  // changeEmail: async (token, email, newEmail) => {
  //   const lang = getLang();
  //   return await axios.post(`${lang}/account/change-email?token=${token}&email=${email}&newEmail=${newEmail}`);
  // },
  changeEmail: async (token, email, newEmail) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-email`, {
      email: email,
      newEmail: newEmail,
      token: token,
    });
  },
  addUserToATS: async (user, atsId) => {
    const lang = getLang();
    return await axios.put(`${lang}/account?email=${user.email}&id=${user.id}&atsId=${atsId}`);
  },
  createAtsAccount: async (fields) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/register-ats-user`, {
      email: fields.email,
      displayName: fields.accountName,
      password: fields.password,
      phoneNumber: fields.phoneNumber,
    });
  },
  createAdminAccount: async (fields) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/register-admin-user`, {
      email: fields.email,
      displayName: fields.name,
      password: fields.password,
      phoneNumber: fields.phoneNumber,
    });
  },
  // getUser: async (email, id) => {
  //   return await axios.get(`${lang}/account/user?email=${email}&id=${id}`);
  // },
  getUsers: async (page = 1) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/users?pageNumber=${page}&pageSize=20`);
  },
  getUsersByRole: async (pageNumber = 1, pageSize = 20, role) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/users-by-role?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}`);
  },
  searchUsersByRole: async (pageNumber = 1, pageSize = 20, role, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/search/users-by-role?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}&search=${search}`);
  },
  getUsersByRoleWithAts: async (pageNumber = 1, pageSize = 20, role) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/users-by-role-with-ats?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}`);
  },
  searchUsersByRoleWithAts: async (pageNumber = 1, pageSize = 20, role, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/search/users-by-role-with-ats?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}&search=${search}`);
  },
  deleteUser: async (email, password) => {
    const lang = getLang();
    return await axios.delete(`${lang}/account/delete?email=${email}&password=${password}`);
  },
  banUser: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/ban-user?email=${email}`);
  },
  unBanUser: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/un-ban-user?email=${email}`);
  },
  patchUser: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/account/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
};

export const smsApi = {
  sendOne: async (toPhoneNumber, toName, fromPhoneNumber) => {
    const lang = getLang();
    return await axios.post(`${lang}/sms`, {
      recipient: {
        phoneNumber: toPhoneNumber,
        name: toName,
      },
      fromPhoneNumber: fromPhoneNumber,
      type: 0,
    });
  },
  active: async (icaoLocationIndicator) => {
    const lang = getLang();
    return await axios.post(`${lang}/sms/active?icaoLocationIndicator=${icaoLocationIndicator}`);
  },
};

export const requestsApi = {
  getActiveByAts: async (icaoLocationIndicator) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/active/ats?icaoLocationIndicator=${icaoLocationIndicator}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  getUpcomingByAts: async (pageNumber = 1, pageSize = 5, icaoLocationIndicator) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/upcoming/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&icaoLocationIndicator=${icaoLocationIndicator}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  getEarlierByAts: async (pageNumber = 1, pageSize = 5, icaoLocationIndicator) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/earlier/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&icaoLocationIndicator=${icaoLocationIndicator}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  getErasedByAts: async (pageNumber = 1, pageSize = 5, icaoLocationIndicator) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/erased/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&icaoLocationIndicator=${icaoLocationIndicator}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  searchActiveByAts: async (icaoLocationIndicator, search) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/search/active/ats?icaoLocationIndicator=${icaoLocationIndicator}&search=${search}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  searchUpcomingByAts: async (pageNumber, pageSize, icaoLocationIndicator, search) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/search/upcoming/ats?icaoLocationIndicator=${icaoLocationIndicator}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  searchEarlierByAts: async (pageNumber, pageSize, icaoLocationIndicator, search) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/search/earlier/ats?icaoLocationIndicator=${icaoLocationIndicator}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  searchErasedByAts: async (pageNumber, pageSize, icaoLocationIndicator, search) => {
    const lang = getLang();
    const response = await axios.get(`${lang}/requests/search/erased/ats?icaoLocationIndicator=${icaoLocationIndicator}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    response.data.forEach((request) => {
      request.geoPolygon = wktPolygonToCoordinateArray(request.geoPolygon);
      request.geoPolygonBuffer = wktPolygonToCoordinateArray(request.geoPolygonBuffer);
    });
    return response;
  },
  getUpcomingByUser: async (pageNumber = 1, pageSize = 5, email) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/upcoming/user?email=${email}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getEarlierByUser: async (pageNumber = 1, pageSize = 5, email) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/earlier/user?email=${email}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getErasedByUser: async (pageNumber = 1, pageSize = 5, email) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/erased/user?email=${email}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  searchUpcomingByUser: async (pageNumber, pageSize, email, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/search/upcoming/user?email=${email}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  searchEarlierByUser: async (pageNumber, pageSize, email, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/search/earlier/user?email=${email}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  searchErasedByUser: async (pageNumber, pageSize, email, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/search/erased/user?email=${email}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  postRequest: async (request) => {
    const lang = getLang();
    return await axios.post(
      `${lang}/requests`,
      {
        height: request.height,
        radius: request.radius,
        address: request.address,
        lat: request.latitude,
        lng: request.longitude,
        estimatedFlightTime: request.estimatedFlightTime,
        plannedDate: request.plannedDate,
        ats: request.ats,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },
  postCopiedRequest: async (request, plannedDate) => {
    const lang = getLang();
    return await axios.post(`${lang}/requests/copy?requestId=${request.id}&email=${request.email}&plannedDate=${plannedDate}`);
  },
  patchRequest: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/requests/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
  getAtsByRequest: async (requestId) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/ats?requestId=${requestId}`);
  },
};

export const atsApi = {
  getAts: async (id) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/${id}`);
  },
  getAtses: async (pageNumber = 1, pageSize = 20) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getAtsesTest: async (page = 1) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/test?pageNumber=${page}&pageSize=20`);
  },
  getAtsesProduction: async (page = 1) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/production?pageNumber=${page}&pageSize=20`);
  },
  searchAtses: async (pageNumber = 1, pageSize = 20, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/search?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`);
  },
  postAts: async (fields, airspace, emailNotifications) => {
    const lang = getLang();
    return await axios.post(`${lang}/ats`, {
      name: fields.name,
      phoneNumber: fields.phoneNumber,
      lat: fields.latitude,
      lng: fields.longitude,
      airspace: airspace,
      disclaimer: fields.disclaimer,
      emailNotifications: emailNotifications,
      userEmail: fields.email,
      userPassword: fields.password,
      userName: fields.email,
      UserDisplayName: fields.accountName,
    });
  },
  patchAts: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/ats/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
  toggleBetaFeatures: async (id) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/toggle?${id}`);
  },
};

export const commentsApi = {
  getCommentsByUser: async (email, page = 1, pageSize = 5) => {
    const lang = getLang();
    return await axios.get(`${lang}/comments?email=${email}&pageNumber=${page}&pageSize=${pageSize}`);
  },
  postComment: async (user, comment, email) => {
    const lang = getLang();
    return await axios.post(`${lang}/comments?email=${email}`, {
      author: user.email,
      body: comment,
      date: convertToUnixTimeStamp(),
    });
  },
};

export const restrictedZonesApi = {
  getRestrictedZones: async (page = 1, pageSize = 1000) => {
    const lang = getLang();
    return await axios.get(`${lang}/restrictedzones?pageNumber=${page}&pageSize=${pageSize}`);
  },
  getRestrictedByAts: async (atsId, page = 1, pageSize = 1000) => {
    const lang = getLang();
    return await axios.get(`${lang}/restrictedzones/ats?pageNumber=${page}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  postRestrictedZone: async (fields, ats, area) => {
    const lang = getLang();
    return await axios.post(`${lang}/restrictedzones`, {
      name: fields.name,
      area: area,
      lat: fields.latitude,
      lng: fields.longitude,
      radius: fields.radius,
      ats: ats,
    });
  },
  putRestrictedZone: async (fields, selected, area) => {
    const lang = getLang();
    return await axios.put(`${lang}/restrictedzones`, {
      id: selected.id,
      name: fields.name,
      area: area,
      lat: fields.latitude,
      lng: fields.longitude,
      radius: fields.radius,
      ats: selected.ats,
    });
  },
  deleteRestrictedZone: async (id) => {
    const lang = getLang();
    return await axios.delete(`${lang}/restrictedzones/${id}`);
  },
};

export const standardRequestsApi = {
  getStandardRequests: async (page = 1, pageSize = 10) => {
    const lang = getLang();
    return await axios.get(`${lang}/standardrequests?pageNumber=${page}&pageSize=${pageSize}`);
  },
  getStandardByAts: async (pageNumber = 1, pageSize = 10, atsId) => {
    const lang = getLang();
    return await axios.get(`${lang}/standardrequests/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  postStandardRequest: async (fields, ats, area) => {
    const lang = getLang();
    return await axios.post(`${lang}/standardrequests`, {
      name: fields.name,
      area: area,
      lat: fields.latitude,
      lng: fields.longitude,
      ats: ats,
    });
  },
  putStandardRequest: async (fields, selected, area) => {
    const lang = getLang();
    return await axios.put(`${lang}/standardrequests`, {
      id: selected.id,
      name: fields.name,
      area: area,
      lat: fields.lat,
      lng: fields.lng,
      ats: selected.ats,
    });
  },
  deleteStandardRequest: async (id) => {
    const lang = getLang();
    return await axios.delete(`${lang}/standardrequests/${id}`);
  },
};

export const settingsApi = {
  getPageSize: async () => {
    const lang = getLang();
    return await axios.get(`${lang}/settings/page`);
  },
  updatePageSize: async (pageSize) => {
    const lang = getLang();
    return await axios.put(`${lang}/settings/page?pageSize=${pageSize}`);
  },
};

export const countriesApi = {
  getCountries: async () => {
    const lang = getLang();
    return await axios.get(`${lang}/countries`);
  },
};

export const dronesApi = {
  get: async (icaoLocationIndicator, centerLatitude, centerLongitude) => {
    return await axios.get(`/drones?futureMinutes=5&pastMinutes=0&icaoLocationIndicator=${icaoLocationIndicator}&centerLatitude=${centerLatitude}&centerLongitude=${centerLongitude}`);
  },
};

export const airspaceApi = {
  getCtrs: async (latitude, longitude) => {
    const result = await axios.get(`/airspace/ctrs?centerLatitude=${latitude}&centerLongitude=${longitude}`, {
      timeout: 10000,
    });
    return result.data;
    // const parsedResult = result.data.map((area) => {
    //   const coordinates = JSON.parse(area.coordinates);
    //   const newCoords = coordsArrToObj(coordinates);
    //   return { ...area, coordinates: newCoords };
    // });
    // return parsedResult;
  },
  getRestrictedAreas: async (latitude, longitude) => {
    const result = await axios.get(`/airspace/restrictedareas?centerLatitude=${latitude}&centerLongitude=${longitude}`, {
      timeout: 10000,
    });
    return result.data;
    // const parsedResult = result.data.map((area) => {
    //   const coordinates = JSON.parse(area.coordinates);
    //   const newCoords = coordsArrToObj(coordinates);
    //   return { ...area, coordinates: newCoords };
    // });
    // return parsedResult;
  },
  getRunwayBuffers: async (latitude, longitude) => {
    const result = await axios.get(`/airspace/runwaybuffers?centerLatitude=${latitude}&centerLongitude=${longitude}`, {
      timeout: 10000,
    });
    return result.data;
    // const parsedResult = result.data.map((area) => {
    //   const coordinates_highres = JSON.parse(area.coordinates_highres);
    //   const newCoordsHighRes = coordsArrToObj(coordinates_highres);

    //   const coordinates_lowres = JSON.parse(area.coordinates_lowres);
    //   const newCoordsLowRes = coordsArrToObj(coordinates_lowres);

    //   return {
    //     ...area,
    //     coordinates_highres: newCoordsHighRes,
    //     coordinates_lowres: newCoordsLowRes,
    //   };
    // });
    // return parsedResult;
  },
  getHelipadBuffers: async (latitude, longitude) => {
    const result = await axios.get(`/airspace/helipadbuffers?centerLatitude=${latitude}&centerLongitude=${longitude}`, {
      timeout: 10000,
    });
    return result.data;
    // const parsedResult = result.data.map((area) => {
    //   const coordinatesCenter = JSON.parse(area.coordinates_center);
    //   const newCoordsCenter = { latitude: coordinatesCenter[0], longitude: coordinatesCenter[1] };

    //   const coordinates_highres = JSON.parse(area.coordinates_highres);
    //   const newCoordsHighRes = coordsArrToObj(coordinates_highres);

    //   const coordinates_lowres = JSON.parse(area.coordinates_lowres);
    //   const newCoordsLowRes = coordsArrToObj(coordinates_lowres);

    //   return {
    //     ...area,
    //     coordinates_center: newCoordsCenter,
    //     coordinates_highres: newCoordsHighRes,
    //     coordinates_lowres: newCoordsLowRes,
    //   };
    // });
    // return parsedResult;
  },
  getAerodomeReferencePoints: async (latitude, longitude) => {
    const result = await axios.get(`/airspace/aerodomereferencepoints?centerLatitude=${latitude}&centerLongitude=${longitude}`, {
      timeout: 10000,
    });
    return result.data;
  },
  healthy: async () => {
    const result = await axios.get(`/airspace/healthy`, {
      timeout: 10000,
    });
    return result.data;
  },
};

export const elevationApi = {
  get: async (id) => {
    const result = await axios.get(`/elevation?requestId=${id}`);
    return result.data;
  },
};

export const touchedSectorsApi = {
  get: async (atsId, requestId) => {
    const result = await axios.get(`/touchedsectors?atsId=${atsId}&requestId=${requestId}`);
    return result.data;
  },
};

export const printStripApi = {
  post: async (strip) => {
    const result = await axios.post(`http://localhost:9010`, strip, {
      headers: {
        Type: "FPL",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return result.data;
  },
};

export const healthyApi = {
  get: async () => {
    return await axios.get(`/healthy`);
  },
};
